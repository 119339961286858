import css from 'styled-jsx/css'
import { colors } from '../../styles'

const bgBucket = 'https://s3.eu-west-1.amazonaws.com/images.stvqa.tv/background_images/'
const logoBucket = 'https://s3.eu-west-1.amazonaws.com/images.stvqa.tv/background_images/logos/'

const freeSuccessBg = `${bgBucket}bg-free-modal.png`
const plusSuccessBg = `${bgBucket}bg-plus-modal.png`
const psSuccessBg = `${bgBucket}bg-sports-modal.png`
const freeSuccessBgMobile = `${bgBucket}bg-free-modal-mobile.png`
const plusSuccessBgMobile = `${bgBucket}bg-plus-modal-mobile.png`
const psSuccessBgMobile = `${bgBucket}bg-psports-modal-mobile.jpg`
const playerLogoLarge = `${logoBucket}STV_Player_Logo_LARGE.png`
const playerPlusLogoLarge = `${logoBucket}STVPlus_LARGE.png`
const playerSportsLogoLarge = `${logoBucket}STVPlusSPORTS_small.png`
const playerLogoSmall = `${logoBucket}STVPLAYER_small.png`
const playerPlusLogoSmall = `${logoBucket}STVPlus_small.png`
const playerSportsLogoSmall = `${logoBucket}STVPlusSPORTS_LARGE.png`


export default css`
  .success-modal{
    background-color: ${colors.baseBg};
    display: flex;
    flex-flow: wrap;
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%
  }

  .success-modal-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 0 auto;
  }

  .success-image, .success-copy-container {
    width: 100%;
    order: 0;
  }

  .success-copy-container {
    z-index: 4;
    text-align: center;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 1;
    margin: 0 0 70px 0;
    padding: 0 25px;
    transform: translateY(-25%);
  }

  .success-copy-container.premier_sports {
  }

  .success-copy {
    margin: 0 auto;
    max-width: 660px;
    /* margin: 0 50px; */
  }

  .success-image {
    background: url(${freeSuccessBgMobile});
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    min-height: 250px;
    height: 50vh;
    z-index: 1;
  }

  .success-image:after {
    content: '';
    background: linear-gradient(180deg, rgba(21, 21, 21, 0) -1.83%, rgba(21, 21, 21, 0.7) 31.87%, #151515 73.07%);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    height: 150px;
  }

  .success-image.plus {
    background: url(${plusSuccessBgMobile});
  }

  .success-image.premier_sports {
    background: url(${psSuccessBgMobile});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }

  .logo {
    width: 376px;
    height: 43px;
    top: 54px;
    left: 54px;
    background-position: center;
  }

  .logo.free {
    background-image: url(${playerLogoSmall});
    background-repeat: no-repeat;
    background-size: contain;
  }

  .logo.plus {
    background-image: url(${playerPlusLogoSmall});
    background-repeat: no-repeat;
    background-size: contain;
    width: 140px;
    height: 44px;
  }

  .logo.premier_sports {
    background-image: url(${playerSportsLogoSmall});
    background-repeat: no-repeat;
    background-size: contain;
    width: 230px;
  }

  h1.success-headline {
    font-size: 56px;
    font-weight: 600;
    line-height: 1;
    margin: 25px 0 30px 0;
  }

  p.success-subtitle {
    font-size: 28px;
    font-weight: 400;
    line-height: 35px;
    color: #F6F6F6;
  }

  .success-button-container {
    max-width: 375px;
    border-radius: 8px;
    cursor: pointer;
    background: ${colors.primaryText};
    margin: 45px auto 0 auto;
  }

  .highlight {
    color: transparent;
  }

  .highlight.free {
    background: linear-gradient(151.62deg, #938DFE 13.62%, #8C42E1 71.59%, #6135D8 108.19%, #4302C2 159.26%);
    background-clip: text;
  }

  .highlight.plus {
    background: linear-gradient(105.34deg, #81EC20 3.25%, #00E5DF 46.46%, #948FFF 83.4%, #14CC7D 123.48%);
    background-clip: text;
  }

  .highlight.premier_sports {
    background: linear-gradient(125.86deg, #F0950D -7.01%, #FFFF00 74.17%);
    background-clip: text;
  }

  @media (min-width: 1024px) {
     .success-modal{
      width: 100%;
      display: flex;
      flex-flow: wrap;
    }

    .success-modal-inner {
      overflow: hidden;
      width: 100%;
      flex-flow: unset;
    }

    .success-image, .success-copy-container {
      width: 50%;
      height: 100%;
    }

    .success-copy-container {
      position: relative;
      text-align: left;
      max-width: unset;
      flex-direction: row;
      order: 0;
      transform: none;
    }

    .success-image:before, .success-image:after {
      content: unset;
    }

    .success-image {
      background: url(${freeSuccessBg});
      order: 1;
    }


    .success-image.plus {
      background: url(${plusSuccessBg});
    }

    .success-image.premier_sports {
      background: url(${psSuccessBg});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

    }

    .logo {
      position: absolute;
      width: 19.5vw;
      height: 3.17vw;
      top: 50px;
      left: 50px;
      margin: unset;
      background-position: left;
    }

    .logo.free {
      background-image: url(${playerLogoLarge});
      background-repeat: no-repeat;
      background-size: contain;
    }

    .logo.plus {
      background-image: url(${playerPlusLogoLarge});
      background-repeat: no-repeat;
      background-size: contain;
      width: 19.5vw;
    }

    .logo.premier_sports {
      background-image: url(${playerSportsLogoLarge});
      background-repeat: no-repeat;
      background-size: contain;
      width: 19.5vw;
    }

    .success-copy {
      width: 100%;
      max-width: 660px;
      margin: 0 50px;
    }

    h1.success-headline {
    }

    .success-button-container {
      margin: 0;
    }
  }

  @media (min-width: 1920px) {


    h1.success-headline {
      font-size: 54px;
      font-weight: 600;
      line-height: 67px;
    }

    .success-button-container {
      max-width: 376px;
      line-height: 1vw;
      margin-top: 0;
    }

  }

  @media (max-width: 767px) {

    .success-copy-container{
      padding: 0 16px !important;
    }
    .success-headline {
      font-size: 32px !important;
    }
    .success-subtitle {
      font-size: 16px !important;
      line-height: 19px !important;
    }
    .success-copy {
      max-width: 390px;
    }
  }
`
