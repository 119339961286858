import qs from 'qs'
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  reduxForm,
  Field,
  SubmissionError,
} from 'redux-form'
import { Link } from 'react-router-dom'
import loginService from '../../initLoginService'
import {
  isRequired,
  isMinLength6,
  isMatchingPassword,
} from '../../modules/validated-fields'
import StvLogo from '../../components/StvLogo'
import { getPlayerUrl } from '../../modules/utils'
import InputField from '../../components/InputField'
import Button from '../../components/Button'
import CookieStatement from '../../components/CookieStatement'
import { trackEvent } from '../../modules/dtm-helpers'
import setPageName from '../../utils/pageName'
import ResetPasswordStyles from './styles'
import { errors } from '../../styles'
import { setUser } from '../../actions/user_actions'

class ResetPassword extends Component {
  componentDidMount() {
    trackEvent('passwordResetForm', {})
    setPageName({
      page: 'login',
      guid: 'passwordnew',
    })
  }

  signInUser = async (password) => {
    const { email } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    try {
      const user = await loginService.signInUser({ email, password })
        .then((theUser) => {
          this.props.setUser(theUser)
          this.getNewsLetter(theUser['cognito:username'])
        })
      trackEvent('logInSuccess', { user_cognito_id: user['cognito:username'] })
    } catch (error) {
      throw new SubmissionError({
        _error: 'Sorry, something went wrong.',
      },
      trackEvent('formSubmitFailed', {}))
    }
  }

  requestPasswordChange = (values) => {
    const password = values.passwordValue
    const { username } = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    const { onSuccess } = this.props
    return loginService.resetPassword(username, password)
      .then(setTimeout(() => this.signInUser(password), 1500))
      .then(onSuccess)
      .catch((err) => {
        throw new SubmissionError({
          _error: 'Sorry, something went wrong, please try again.',
        })
      })
  }

  render() {
    const {
      error,
    } = this.props

    const closeUrl = getPlayerUrl()
    const { email } = qs.parse(window.location.search, { ignoreQueryPrefix: true })

    return (
      <div className="FormWrapper">
        <div className="content-wrapper" style={{ height: '90%' }}>
          <div className="form-head">
            <StvLogo center />
          </div>

          <div className="form-content">
            {error
              ? (
                <Fragment>
                  <p>{error}</p>
                  <Link
                    to="/"
                    className="link"
                  >
                    Go back and try again.
                  </Link>
                </Fragment>
              )
              : (
                <div>
                  <h1 className="big-heading" style={{ marginBottom: '35px' }}>New password</h1>
                  <div className="email-used-wrapper">
                    <p className="grey-text">Create a new password to sign up with: </p>
                    <p className="highlighted-text">{email.toLowerCase()}</p>
                  </div>
                  <form onSubmit={this.props.handleSubmit(this.requestPasswordChange)}>
                    <Field
                      name="passwordValue"
                      id="enter-password"
                      component={InputField}
                      props={{
                        type: 'password',
                        showHide: true,
                      }}
                      placeholders="New Password"
                      autoFocus
                      description="This must be at least 6 characters long."
                      validate={[isRequired, isMinLength6]}
                    />

                    <Field
                      name="passwordConfirmValue"
                      id="confirm-password"
                      component={InputField}
                      props={{
                        type: 'password',
                        showHide: true,
                      }}
                      placeholders="Re-enter Password"
                      validate={[isRequired, isMinLength6, isMatchingPassword]}
                      passwordConfirmWrapper
                    />
                    <div className="terms-and-button-wrapper" style={{ bottom: '36px' }}>
                      <CookieStatement />
                      <div className="button-wrapper">
                        <Button
                          type="submit"
                        >
                          Confirm new password
                        </Button>
                      </div>
                      <div className="button-wrapper">
                        <Button
                          variant="secondary"
                          href={closeUrl}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
          </div>
        </div>
        <style jsx>{ResetPasswordStyles}</style>
        <style jsx>{errors}</style>
      </div>
    )
  }
}

ResetPassword.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  setUser: PropTypes.func.isRequired,
}

ResetPassword.defaultProps = {
  error: '',
}

const mapDispatchToProps = { setUser }

const mapStateToProps = state => ({
  user: state.user.email,
})

export default compose(reduxForm({
  form: 'SignUpLoginForm',
  destroyOnUnmount: true,
})(connect(mapStateToProps, mapDispatchToProps)(ResetPassword)))
