import React, { lazy, Suspense } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'
import withAuthentication from '../../HOCs/withAuthentication'


const EnhancedPersonalDetails = lazy(() => import('./PersonalDetails').then((module) => {
  const PersonalDetails = module.default
  const enhancedPersonalDetails = compose(
    withRouter,
    withUser,
    withAuthentication,
  )
  return { default: enhancedPersonalDetails(PersonalDetails) }
})
)

const AsyncPersonalDetails = props => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <EnhancedPersonalDetails {...props} />
  </Suspense>
)

export default AsyncPersonalDetails

