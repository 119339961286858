import React, { lazy, Suspense } from 'react'
import LoadableSpinner from '../../components/LoadableSpinner'
import withAuthentication from '../../HOCs/withAuthentication'


const EnhancedChangePassword = lazy(() => import('./ChangePassword').then((module) => {
  const ChangePassword = module.default
  return { default: withAuthentication(ChangePassword) }
})
)

// a wrapper component that handles Suspense
const AsyncChangePassword = props => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <EnhancedChangePassword {...props} />
  </Suspense>
)

export default AsyncChangePassword




