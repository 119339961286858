import React, { lazy, Suspense } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'
import withAuthentication from '../../HOCs/withAuthentication'


const EnhancedPlus = lazy(() => import('./Plus').then((module) => {
  const Plus = module.default
  const enhancedPlus = compose(
    withRouter,
    withUser,
    withAuthentication,
  )
  return { default: enhancedPlus(Plus) }
})
)

const AsyncPlus = props => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <EnhancedPlus {...props} />
  </Suspense>
)

export default AsyncPlus


