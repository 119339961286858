import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
  .Login {
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
  }

  :global(.login-link) {
    margin: 0 0 0 auto;
  }

  .avatar-outer {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }

  .signed-out {
    padding: 8px 12px;
  }

  button {
    border: 0;
    background: none;
    font-family: inherit;
  }

  .player-plus a {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: ${colors.secondaryText};
    white-space: nowrap;
  }

  .player-plus strong {
    font-weight: 700;
    color: ${colors.primaryText};
  }


  a, button {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    color: ${colors.primaryText};
  }

  a.log-in-link {
    display: flex;
    gap: 8px;
    height: 24px;
  }

  .avatar-container {
    width: 24px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }

  .menu-container {
    background: linear-gradient(0deg, ${colors.baseBg}, ${colors.baseBg}),
    linear-gradient(0deg, rgba(246, 246, 246, 0.1), rgba(246, 246, 246, 0.1));
    border: 1px solid #F6F6F61A;
    border-radius: 4px;
    display: none;
  }

  .menu-container:before{
    content:'';
    display:block;
    width:0;
    height:0;
    position:absolute;

    border-left: 16px solid transparent;
    border-bottom: 11px solid ${colors.ctvBg};
    border-right:16px solid transparent;
    right: 1.7rem;

    top: -0.7rem;
    z-index: -1;
  }

  .menu-container.active {
    display: block;
  }

  .accountMenu {
    top: 100%;
    z-index: 2;
    margin-right: 0rem;
    margin-top: 0;
    width: 100%;
    padding: 8px;
    flex-direction: column;
    gap: 4px;
    list-style: none;
  }

  ul.accountMenu {
    margin-block-end: 0px;
  }

  .accountMenu.active {
    display: flex;
  }

  .menu-item a,
  .menu-item button {
    width: 100%;
    padding: 6px 12px 6px 12px;
    border-radius: 8px;
    gap: 10px;
  }

  .menu-item button {
    text-align: left;
  }

  .menu-item:hover a,
  .menu-item:hover button {
    background: ${colors.ctvBg};
  }

  .menu-item:nth-last-child(2) a,
  .menu-item:nth-last-child(2) button {
    margin-bottom: 8px;
  }

  .menu-item:last-child a,
  .menu-item:last-child a {
    margin-top: 8px;
  }

  .menu-link,
  .account-menu-link {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    color: ${colors.white};
  }

  .Login {
    padding: 0;
  }

  .Login .menu-item {
    color: ${colors.white};
  }

  // .menu-item button.menu-link,
  // .menu-item button.account-menu-link {
  //   padding-inline-start: unset;
  //   padding-inline-end: unset;
  //   padding-block-start: unset;
  //   paddeing-block-end: unset;
  // }

  .close-icon-wrapper {
    display: none;
  }

  @media (max-width: 1199px) {
    .PlayerHeader {
      padding: 16px 24px;
    }

    .nav {
      padding: 0;
    }

    .settings-menu, .swap-icons {
      gap: 12px;
    }

    .settings-menu .swap-icons .SearchLink:focus,
    .settings-menu .swap-icons .login-link:focus,
    .settings-menu #player-nav-menu:focus {
      background: ${colors.ctvBg};
    }
  }

  @media (max-width: 900px){
    .avatar-outer.active {
      background: ${colors.ctvBg};
    }

    .menu-container {
      right: -1.5rem;
    }
  }

  @media (max-width: 1023px){
    .player-plus {
      display: block;
    }
  }

  @media (max-width: 767px) {
    p.login-text {
      display: none;
    }

    .player-plus {
      display: none;
    }

    .menu-container {
      background: rgba(21, 21, 21, 0.75);
      backdrop-filter: blur(10px);
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      overflow: scroll;
      padding-bottom: 48px;
      padding-top: 48px;
      display: none;
      width: 100%;
      overflow-x: hidden;
    }

    .menu-container.active {
      display: block;
    }

    .close-icon-wrapper {
      display: block;
    }

    .menu-header {
      display: flex;
      align-items: center;
      padding-right: 16px;
    }

    .menu-body {
      position: relative;
      min-height: 400px;
      height: 80vh;
    }

    ul.accountMenu {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      display: block;
      width: fit-content;
    }

    li.menu-item {
      padding: 6px 12px;
      text-align: center;
      width: 270px;
      border-radius: 8px;
      margin-bottom: 10px;
      justify-content: center;
    }

    li.menu-item:last-child {
      position: absolute;
      bottom: 85px;
    }

    li.menu-item.active {
      background: ${colors.primaryText};
    }

    li.menu-item.active a {
      color: ${colors.textInput};
    }

    .menu-item button {
      text-align: center;
    }

    .menu-body {
      padding-top: 16px;
      display: flex;
      justify-content: center;
    }

    .close-icon-wrapper {
      margin-left: auto;
      width: 43px;
      height: 43px;
    }

    button#close-button.close-icon {
      background: transparent;
      border: none;
      padding: none;
    }
  }

  @media (min-width: 600px) {
    a, button {
      min-width: 0;
    }
  }

  @media (min-width: 900px) {
    a, button {
      font-size: 16px;
    }

    .accountMenu {
      left: auto;
    }

    .menu-item .menu-link,
    .menu-item .account-menu-link {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.25;
    }
  }
`
