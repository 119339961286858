/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import styles from './styles'
import links from './config'
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
} from '../icons'

const PageFooter = () => (
  <footer className="PageFooter">


    <div className="inner">
      <div className="links">
        {links.map(({ title, show, items }) => (
          <ul className={`show-${show}`} key={title}>
            <h4>{title}</h4>
            {items.map(({ text, url }) => (
              <li key={text}>
                <a href={url} title={text}>{text}</a>
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>

    <hr className="show-tablet" />
    <div className="lower-footer">
      <div className="legal">
        <p>&copy; Copyright 2007-{new Date().getFullYear()} STV. All rights reserved.</p>
        <p>Cookie Info: To find out how to manage cookies, see our <a href="/terms-of-use/#cookies">cookie policy</a>.</p>
      </div>
      <ul className="social">
        <li className="instagram">
          <a href="https://www.instagram.com/stvplayeruk/?hl=en-gb">
            <InstagramIcon />
          </a>
        </li>
        <li>
          <a href="http://twitter.com/WeAreSTV">
            <TwitterIcon />
          </a>
        </li>
        <li>
          <a href="http://facebook.com/WeAreSTV">
            <FacebookIcon />
          </a>
        </li>
      </ul>
    </div>

    <style jsx>{styles}</style>
  </footer>
)

export default PageFooter
