import React, {
  lazy, Suspense, useEffect, useState,
} from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { compose } from 'redux'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'

// Lazy load the Login component
const AsyncLogin = lazy(() => import('./Login'))

const LoginContainer = ({ user, ...props }) => {
  const [initialRender, setInitialRender] = useState(false)

  useEffect(() => {
    setInitialRender(true)
  }, [])

  if (!initialRender && user && user.sub) {
    return <Redirect to="/personal-details" />
  }

  return (
    <Suspense fallback={<LoadableSpinner centered />}>
      <AsyncLogin {...props} />
    </Suspense>
  )
}

LoginContainer.propTypes = {
  user: PropTypes.oneOfType([
    PropTypes.shape({
      sub: PropTypes.string,
    }),
    PropTypes.bool,
  ]).isRequired,
}

const enhanceLoginContainer = compose(withUser)

export default enhanceLoginContainer(LoginContainer)
