import React, { lazy, Suspense } from 'react'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'


const EnhancedTermsOfUse = lazy(() => import('./StvAndYou').then((module) => {
  const TermsOfUse = module.default
  return { default: withUser(TermsOfUse) }
})
)

const AsyncTermsOfUse = props => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <EnhancedTermsOfUse {...props} />
  </Suspense>
)

export default AsyncTermsOfUse
