import React from 'react'
import {
  Router, Switch, Route, Redirect,
} from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { createStore, combineReducers } from 'redux'
import { Provider } from 'react-redux'
import { reducer as formReducer } from 'redux-form'
import { createBrowserHistory } from 'history'
import userReducer from './reducers/user'
import featureFlagsReducer from './reducers/featureFlags'
import App from './components/App'
import DashboardRoute from './routes/layouts/DashboardRoute'
import PortalRoute from './routes/layouts/PortalRoute'
import PersonalDetails from './routes/PersonalDetails'
import Plus from './routes/Plus'
import PaymentDetails from './routes/PaymentDetails'
import ChangePassword from './routes/ChangePassword'
import VipPage from './routes/VipPage'
import Privacy from './routes/Privacy'
import LoginSplashScreen from './routes/LoginSplashScreen'
import TrackPage from './routes/TrackPage'
import Activate from './routes/Activate'
import Login from './routes/Login'
import ResetPassword from './routes/ResetPassword'
import ResetPasswordAuthenticated from './routes/ResetPasswordLink'
import UpdateNewsletter from './routes/UpdateNewsletter'
import Article from './routes/Article'
import TermsOfUse from './routes/TermsOfUse'
import StvAndYou from './routes/StvAndYou'
import Marketing from './routes/Marketing'
import ErrorPage from './components/Error'
import { setTpNumber } from './modules/utils/dataApi'
import GoogleTagManager, { trackGoogleTagManagerEvent } from './modules/gtm-helpers'

const gtmId = 'GTM-N8943NG6' // Live container
// const gtmId = 'GTM-MBM3BFSC' // QA container

const rootReducer = combineReducers({
  form: formReducer,
  user: userReducer,
  featureFlags: featureFlagsReducer,
})

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const history = createBrowserHistory()
trackGoogleTagManagerEvent('page_view', {
  page: location?.pathname, // eslint-disable-line
})

history.listen((location) => {
  trackGoogleTagManagerEvent('page_view', {
    page: location?.pathname,
  })
})

window.setTpNumber = setTpNumber

createRoot(document.getElementById('root'),)
  .render(
    <Provider store={store}>
      <GoogleTagManager gtmId={gtmId} />
      <Router history={history}>
        <App>
          <Switch>
            <PortalRoute exact path="/" component={Login} />
            <PortalRoute exact path="/(firetv|freesat|freeview|samsung|youview|virginmedia|sky|skyq|activate|googletv)/" component={Activate} />
            <PortalRoute exact path="/forgottenpassword" component={ResetPassword} />
            <PortalRoute exact path="/reset-password" component={ResetPasswordAuthenticated} />
            <DashboardRoute exact path="/personal-details" component={PersonalDetails} />
            <DashboardRoute exact path="/plan" component={Plus} />
            <DashboardRoute exact path="/payment-details" component={PaymentDetails} />
            <DashboardRoute exact path="/change-password" component={ChangePassword} />
            <DashboardRoute exact path="/privacy/newsletters">
              <Redirect to="/marketing" />
            </DashboardRoute>
            <DashboardRoute exact path="/login-splash-screen" component={LoginSplashScreen} />
            <DashboardRoute exact path="/track-page" component={TrackPage} />
            <DashboardRoute exact path="/player-vip-sub" component={VipPage} />
            <DashboardRoute exact path="/privacy/:page?" component={Privacy} />
            <DashboardRoute exact path="/marketing" component={Marketing} />
            <DashboardRoute exact path="/terms-of-use" component={TermsOfUse} />
            <DashboardRoute exact path="/stv-and-you" component={StvAndYou} />
            <Route exact path="/newsletters" component={UpdateNewsletter} />
            <DashboardRoute path={/\/(\d*)-/} component={Article} />
            <DashboardRoute exact path="*" component={ErrorPage} />
          </Switch>
        </App>
      </Router>
    </Provider>,
  )
