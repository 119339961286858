/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { HamburgerIcon, CrossIcon } from '../icons'
import StvLogo from '../StvLogo'
import makeIconLink from '../../HOCs/makeIconLink'
import HeaderLoginLink from '../HeaderLoginLink'
import styles from './styles'
import links from './config'
import {
  getCurrentDomain,
  getCurrentUrl,
  getCurrentPath,
  getPlayerUrl,
} from '../../modules/utils'
import withUser from '../../HOCs/withUser'

const HamburgerLink = makeIconLink(HamburgerIcon)
const CURRENT_DOMAIN = getCurrentDomain()
const CURRENT_URL = getCurrentUrl()

const HeaderMenu = ({ user }) => {
  const [menuActive, setMenuActive] = useState(false)
  const [modifiedLinks, setModifiedLinks] = useState(links)
  const currentPath = getCurrentPath()
  const [clicked, setClicked] = useState(false)
  const plusLink = {
    path: getPlayerUrl('/player-plus'),
    title: 'Subscribe to STV Player+',
  }

  // disable body scrolling when mobile menu open
  useEffect(() => {
    if (menuActive) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = null
    }
  }, [menuActive])

  // Update links based on subscription status
  useEffect(() => {
    const currentlySubscribed = user.subscription?.info?.currentlySubscribed
    if (currentlySubscribed || window.innerWidth >= 767) {
      setModifiedLinks(links)
    } else if (!currentlySubscribed && window.innerWidth < 768) {
      setModifiedLinks([...links, plusLink])
    }
  }, [user.subscription?.info?.currentlySubscribed])

  const onBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setMenuActive(false)
    }
  }

  const navClickHandler = (e) => {
    e.preventDefault()
    setMenuActive(false)
    setClicked(!clicked)
  }

  const toggleMenu = (e) => {
    e.preventDefault()
    setMenuActive(!menuActive)
  }

  const hideMenu = (e) => {
    e.preventDefault()
    setMenuActive(false)
  }

  return (
    <div className="HeaderMenu">
      <div className="inner">
        <div className="logo-wrapper">
          <StvLogo />
        </div>
        <nav className="nav">
          <div
            className={`nav-container ${menuActive ? 'menu-active' : ''}`}
            onBlur={onBlur}
          >
            <div className="menu-header">
              <div className="close-icon-wrapper">
                <button
                  className="close-icon button-reset"
                  onClick={hideMenu}
                  type="button"
                  id="close-button"
                  style={{ cursor: 'pointer' }}
                >
                  <CrossIcon width={43} height={43} color="#F6F6F6" />
                </button>
              </div>
            </div>
            <div className="menu-body">
              <ul className={`nav-list ${menuActive ? 'menu-active' : ''}`}>
                {modifiedLinks.map(link => (
                  <li
                    onClick={navClickHandler}
                    role="none"
                    key={link.path}
                    className={`nav-list-item ${clicked} ${link.path.split('/')[1] === currentPath?.split('/')[1] ? 'active' : ''}`}
                  >
                    <NavLink to={link.path} className="menu-link" activeClassName="active" key={link.path}>
                      <span className="menu-item">
                        {link.title}
                      </span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
        <div className="login-wrapper">
          <div className="login-link">
            <HeaderLoginLink currentDomain={CURRENT_DOMAIN} currentUrl={CURRENT_URL} />
          </div>
          <HamburgerLink
            href="#nav-menu"
            className="nav-toggle"
            active={menuActive}
            onClick={toggleMenu}
          />
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}

HeaderMenu.propTypes = {
  user: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    'custom:subscriptions': PropTypes.string,
    sub: PropTypes.string,
    vipOptIn: PropTypes.bool,
    subscription: PropTypes.shape({
      info: PropTypes.object,
    }),
  }),
}

HeaderMenu.defaultProps = {
  user: {
    given_name: null,
    family_name: null,
    'custom:subscriptions': '',
    sub: null,
    vipOptIn: false,
    currentlySubscribed: true,
  },
}

const enhanceHeaderMenu = compose(
  withUser
)

export default enhanceHeaderMenu(HeaderMenu)
