/* eslint-disable no-console */
import getApiUrl from '../getApiUrl'

export default () => ({
  path = '',
  method = 'GET',
  body = null,
}) => {
  const headers = {}
  const environment = getEnv()
  const url = buildRequestUrl({
    environment,
    path,
  })
  const request = () => {
    logRequest({ url, environment })

    return fetchWithTimeout({
      headers,
      url,
      method,
      body,
    })
  }
  return request()
}

const getEnv = () => (window.location.hostname.endsWith('stv.tv')
  ? 'production'
  : 'staging'
)

const logRequest = ({ url, environment }) => {
  if (environment === 'development' || environment === 'staging') {
    console.log(`--- Request made to: ${url} | ${new Date()}`)
  }
}

export function buildRequestUrl({
  environment,
  query,
  path,
  tpPrefix = null,
}) {
  const tp = localStorage.getItem('tpNumber')
  const prefix = (environment !== 'production' && tp) ? `${tp}.` : ''
  const base = [
    `https://${prefix}player.api.`,
    getApiUrl(environment),
    '/v1',
    path,
  ].join('')

  const requestQuery = buildQueryString(query)
  const requestUrl = [
    base,
    requestQuery && `${requestQuery}`,
  ].join('')
  return requestUrl
}

const buildQueryString = (paramsObj = {}) => (
  Object.entries(paramsObj)
    .filter(entry => Boolean(entry[1]))
    .map(entry => entry.map(encodeURIComponent))
    .reduce(
      (queryString, [key, value], i) => `${queryString}${i !== 0 ? '&' : ''}${key}=${value}`,
      '?'
    ))


export function fetchWithTimeout({
  url = '',
  time = 10000,
  headers,
  method,
  body,
}) {
  const timeout = new Promise(resolve => setTimeout(() => {
    const error = {
      success: false,
      reason: `Timed out after ${time}ms: ${url}`,
    }
    resolve(error)
  }, time))
  return Promise.race([
    fetch(url, {
      headers,
      method,
      body,
    })
      .then(r => r.json()),
    timeout,
  ])
}
