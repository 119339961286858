import React, { Component, forwardRef } from 'react'
import PropTypes from 'prop-types'
import ButtonStyles from './styles'

class Button extends Component {
  constructor(props) {
    super(props)
    const { onClick } = this.props
    this.handleClick = onClick
  }

  resolveScopedStyles(scope) {
    return {
      className: scope.props.className,
      styles: scope.props.children,
    }
  }

  renderLoadingDots() {
    return (
      <div className="loading-dots" />
    )
  }

  render() {
    const {
      variant, className, href, disabled, children, loading, id, innerRef, type,
    } = this.props
    const scoped = this.resolveScopedStyles(
      <scope><style jsx>{ButtonStyles}</style></scope>
    )
    const classNames = [
      'button',
      scoped.className,
      ...(variant ? [variant] : []),
      ...(className ? [className] : []),
    ].filter(Boolean).join(' ')
    const Element = href ? 'a' : 'button'

    return (
      <Element
        id={id}
        ref={innerRef}
        type={Element === 'button' ? type : null}
        href={Element === 'a' ? href : null}
        onClick={this.handleClick}
        className={classNames}
        disabled={disabled || loading}
      >
        { loading ? this.renderLoadingDots() : children }
        { scoped.styles }
      </Element>
    )
  }
}

Button.propTypes = {
  id: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.any,
  innerRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  variant: PropTypes.oneOf(['secondary', 'primary', 'close', 'warning', 'blank', 'custom']),
}

Button.defaultProps = {
  id: '',
  href: null,
  onClick: () => null,
  className: '',
  type: 'button',
  disabled: false,
  loading: false,
  children: null,
  innerRef: { current: null },
  variant: 'primary',
}

export default forwardRef((props, ref) => <Button innerRef={ref} {...props} />)
