import React, { lazy, Suspense } from 'react'
import { compose } from 'redux'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'
import withAuthentication from '../../HOCs/withAuthentication'


const EnhancedResetPassword = lazy(() => import('./ResetPasswordLink').then((module) => {
  const ResetPassword = module.default
  const enhancedResetPassword = compose(
    withAuthentication,
    withUser,
  )
  return { default: enhancedResetPassword(ResetPassword) }
})
)

const AsyncResetPassword = props => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <EnhancedResetPassword {...props} />
  </Suspense>
)

export default AsyncResetPassword



