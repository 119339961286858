import React, { lazy, Suspense } from 'react'
import { compose } from 'redux'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'
import withAuthentication from '../../HOCs/withAuthentication'


const EnhancedPrivacy = lazy(() => import('./Privacy').then((module) => {
  const Privacy = module.default
  const enhancedPrivacy = compose(
    withAuthentication,
    withUser,
  )
  return { default: enhancedPrivacy(Privacy) }
})
)

const AsyncPrivacy = props => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <EnhancedPrivacy {...props} />
  </Suspense>
)

export default AsyncPrivacy




