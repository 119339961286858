import React, { lazy, Suspense } from 'react'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'
import withAuthentication from '../../HOCs/withAuthentication'


const EnhancedPaymentDetails = lazy(() => import('./PaymentDetails').then((module) => {
  const PaymentDetails = module.default
  const enhancedPaymentDetails = compose(
    withRouter,
    withAuthentication,
    withUser,
  )
  return { default: enhancedPaymentDetails(PaymentDetails) }
})
)

const AsyncPaymentDetails = props => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <EnhancedPaymentDetails {...props} />
  </Suspense>
)

export default AsyncPaymentDetails
