import cookies from 'js-cookie'

const COOKIE_NAME = 'STV_FEATURE_FLAGS'

/**
 * Retrieves feature flags from the cookie and checks PS enabled status
 * @returns {boolean} Returns true if PS is enabled, false otherwise
 */
export const isPsEnabled = () => {
  try {
    // Get the feature flags cookie
    const cookieValue = cookies.get(COOKIE_NAME)
    if (!cookieValue) return false
    // Parse the cookie and check PS status
    const flags = JSON.parse(decodeURIComponent(cookieValue))
    return Boolean(flags.psEnabled)
  } catch (error) {
    return false
  }
}
