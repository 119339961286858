import React, { lazy, Suspense } from 'react'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'


const EnhancedUpdateNewsletter = lazy(() => import('./UpdateNewsletter').then((module) => {
  const Newsletter = module.default
  return { default: withUser(Newsletter) }
})
)

const AsyncUpdateNewsletter = props => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <EnhancedUpdateNewsletter {...props} />
  </Suspense>
)

export default AsyncUpdateNewsletter
