/**
 * Get the right base API domain for the operating environment.
 *
 * @param {String} environment - the operating environment,
 * e.g. 'development, 'staging' or 'production'
 * @returns {String} stvqa.tv in dev or staging, otherwise stv.tv
 */

export default function getApiUrl(environment) {
  const DEV = 'stvqa.tv'
  const QA = 'stvqa.tv'
  const PRODUCTION = 'stv.tv'

  switch (environment) {
    case 'development': return DEV
    case 'staging': return QA
    default: return PRODUCTION
  }
}
