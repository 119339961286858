import cookies from 'js-cookie'
import {
  SET_USER,
  CLEAR_USER,
  UPDATE_USER_SUBSCRIPTION,
  SET_STRIPE_DETAILS,
  SET_SUBSCRIPTION_ERROR,
} from '../constants/action_types'

const getInitialState = () => ({
  subscription: {
    info: null,
    method: null,
    isSubscribed: false,
    stripeDetails: null,
    error: null,
  },
})

// eslint-disable-next-line default-param-last
export default function user(state = getInitialState(), action) {
  const { type, payload } = action
  const vipOptIn = cookies.get('STV_VIP_OPTIN') === 'true'

  switch (type) {
    case SET_USER:
      return {
        ...payload,
        vipOptIn,
      }

    case UPDATE_USER_SUBSCRIPTION:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          ...payload,
        },
      }

    case SET_STRIPE_DETAILS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          stripeDetails: payload,
        },
      }

    case SET_SUBSCRIPTION_ERROR:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          error: payload,
        },
      }

    case CLEAR_USER:
      return getInitialState()

    default:
      return state
  }
}
