import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const GoogleTagManager = ({ gtmId }) => {
  useEffect(() => {
    // Create the script tag for GTM
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`
    document.head.appendChild(script)

    // Add the dataLayer initialization script
    const dataLayerScript = document.createElement('script')
    dataLayerScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
      });
    `
    document.head.appendChild(dataLayerScript)

    // Cleanup function to remove scripts if the component unmounts
    return () => {
      document.head.removeChild(script)
      document.head.removeChild(dataLayerScript)
    }
  }, [gtmId])

  // Return the noscript part of GTM
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
        title="gtm"
      />
    </noscript>
  )
}

GoogleTagManager.propTypes = {
  gtmId: PropTypes.string.isRequired,
}

export default GoogleTagManager

export const trackGoogleTagManagerEvent = (eventName, eventParams) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: eventName,
    ...eventParams,
  })
}
