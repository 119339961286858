import React, { lazy, Suspense } from 'react'
import LoadableSpinner from '../../components/LoadableSpinner'
import withAuthentication from '../../HOCs/withAuthentication'

// Lazy load the Activate component and enhance it with the authentication HOC
const EnhancedActivate = lazy(() => import('./Activate').then((module) => {
  const Activate = module.default
  return { default: withAuthentication(Activate) }
})
)

// a wrapper component that handles Suspense
const AsyncActivate = props => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <EnhancedActivate {...props} />
  </Suspense>
)

export default AsyncActivate
