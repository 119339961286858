import React, { lazy, Suspense } from 'react'
import { compose } from 'redux'
import LoadableSpinner from '../../components/LoadableSpinner'
import withUser from '../../HOCs/withUser'
import withAuthentication from '../../HOCs/withAuthentication'


const EnhancedMarketing = lazy(() => import('./Marketing').then((module) => {
  const Marketing = module.default
  const enhanceMarketing = compose(
    withAuthentication,
    withUser
  )
  return { default: enhanceMarketing(Marketing) }
})
)

const AsyncMarketing = props => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <EnhancedMarketing {...props} />
  </Suspense>
)

export default AsyncMarketing
