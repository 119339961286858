import css from 'styled-jsx/css'
import { colors } from '../../styles'
import webImage from './WebImage.svg'
import mobileImage from './MobileImage.svg'


export default css`

.success-modal{
  background: linear-gradient(188.64deg, #151515 38.93%, #212121 53.64%, #421A50 83.31%, #7E0FA5 98.42%);
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 18px;
}
.header-wrapper .title{
    text-align: left;
    width: 100%;
    height: 140px;
    left: 64px;
    top: 240px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 70px;
    color: #F6F6F6;
}

.header-wrapper .sub-titletext{
    width: 100%;
    height: 140px;
    left: 64px;
    top: 240px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 70px;
    background: linear-gradient(81.24deg, #F544C6 29.81%, #FBA217 51.05%, #79CC32 74.63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.container-wrapper{
    display: flex;
    flex-direction: row;
    gap: 48px;
}
.displayImage{
    height: auto !important;
    width: 55% !important;
    margin-top: 150px;
    display: block;
    min-height: 700px;
    margin-right: 0px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: default;
    background-image: url(${webImage});
}
.form-close {
    width: 54px;
height: 20px;
font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #F6F6F6;
    position: relative;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    background: transparent;
    outline: 0;
    cursor: pointer;
    padding: 0;
    border: none;
  }
  .form-close:hover {
    background: ${colors.elevatedBg};
    border-radius: 100px;
  }
  .form-action {
    position: absolute;
    top: 0;
    right: 0px;
    margin: 12px;
    margin-right: 32px;
    padding: 0 15px 0 0;
    text-align: right;
    width: 54px;
  }
.image-wrapper{
    position: absolute;
width: 137.12px;
height: 41.69px;
left: 41.14px;
top: 17.84px;
}

  .form-close:after {
    font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #F6F6F6;
    content: 'Logout';
    color: #fff;
    text-indent: 0;
    position: absolute;
    top: -2px;
    display: block;
    width: 100%;
    font-weight: 300;
    z-index: 5;
  }
.button-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 8px;
}
.success-modal .subTitle{
    width: 400px;
    height: 60px;
    left: 64px;
    top: 396px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #F6F6F6;
text-align: left;
}
.success-modal-inner{
  margin: auto;
  height: 100%;
  display: block;
  background: transparent;
  width: 100%;
}

.success-modal-contents {
  background-color: transparent;
  position: static;
  padding-top: 73px;
  margin: auto;
  height: 100%;
}

.success-modal a{
    height: 20px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #F6F6F6;
    line-height: 20px;
}
.logo {
    height: 32px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2%;
}

.success-modal p,
.success-modal span{
  color: ${colors.secondaryText};
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.terms-and-button-wrapper {
  position: absolute;
  padding-top: unset;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: unset;
  bottom: 40px;
  width: 90vw;
  margin: auto;
}

#success-terms {
  text-align: left;
}
.header-wrapper {
    margin-top: 20%;
    width: 40%;
    margin-left: 48px;
  }

#success-header{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    background: linear-gradient(81.24deg, #F544C6 29.81%, #FBA217 51.05%, #79CC32 74.63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.success-modal :global(.success-dismiss){
  background: #9916C7;
  height: 44px;
  border-radius: 8px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  border: none;
  width: 100%;
  line-height: 14px;
}
.success-modal :global(.success-secondary){
  background: transparent;
  height: 42px;
  border-radius: 8px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  border: none;
  width: 100%;
  line-height: 14px;
}

.success-modal :global(.success-dismiss):hover {
  background: ${colors.darkPurple};
}

.success-modal :global(.success-dismiss-cross){
  background: unset;
}
@media (max-height: 750px){
  .success-modal{
    overflow-y: scroll;
    overflow-x: hidden;
  }
}
@media (max-width: 550px){
  .image-wrapper{
    left: 8px;
  }
  .success-modal{
  background: linear-gradient(188.64deg,#151515 100%,#212121 53.64%,#421A50 83.31%,#7E0FA5 98.42%);
  }
  .success-modal-contents{
    padding-top: 154px;
}
.displayImage{
  height: auto !important;
  min-height: 510px;
  width: auto !important;
  margin: 8px;
  background-image: url(${mobileImage});
}
.header-wrapper .sub-titletext{
  font-weight: 700;
  font-size: 46px;
  line-height: 58px;
  color: #F6F6F6;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);

}
.header-wrapper .title{
  margin-top: 70px;
    margin-bottom: 16px;
font-weight: 700;
font-size: 46px;
line-height: 58px;
height: auto;
color: #F6F6F6;
text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
}
.header-wrapper .subTitle{
width: 100%;
font-weight: 400;
font-size: 18px;
line-height: 23px;
color: #F6F6F6;
text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.3);
}
.container-wrapper{
  flex-direction: column-reverse;
}
    .header-wrapper {
        width: 100%;
        margin: auto;
        position: absolute;
    top: 350px;
    padding-left: 16px;
    padding-right: 16px;
      }
      #success-header{
        display: block;
        margin-top: 24px;
      }

.success-modal .subTitle{
  margin: 24px 0px 0px;
}
.terms-and-button-wrapper {
  position: static;
  padding-top: 32px;
  width: 100%;
  margin-bottom: 32px;
}
}
@media (min-width: 550px){
    .success-modal{
      width: 100%;
      height: 100%;
      background-color: #151515;
    }
    .header-wrapper {
        margin-top: 15%;
        width: 40%;
        margin-left: 48px;
      }
    .button-wrapper{
      margin-top: 16px;
      width: 100%;
  }
    .success-modal .subTitle{
      height: auto;
        width: 100%;
        margin: 16px 0px 0px 0px;
    }
    .success-modal-inner{
      position: static;
      width: 720px;
    }
    .title{
      width: 442px;
      height: 140px;
      left: 64px;
      top: 240px;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 70px;
      color: #F6F6F6;
  }
    .success-modal-contents {
      background-color: transparent;
      position: static;
      width: 80%;
      height: 100%;
    }
    .displayImage{
      background-size: revert;
      max-height: 350px;
    }
    .header-wrapper .title{
        font-size: 40px;
        line-height: 46px;
        height: auto;
    }
    .header-wrapper .sub-titletext{
        font-size: 40px;
        line-height: 46px;
        height: auto;
    }
    .header-wrapper {
      margin-top: 150px;
      width: 40%;
      margin-left: 48px;
    }

    .logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .success-modal p,
    .success-modal span{
      color: ${colors.secondaryText};
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      margin: 0px;
    }

    .terms-and-button-wrapper {
      position: static;
      padding-top: unset;
      left: 0;
      right: 0;
      margin-left: -3px;
      margin-right: auto;
      top: unset;
      bottom: 5%;
      width: 300px;
      margin-top: 0px !important;
    }

    #success-terms {
      text-align: left;
    }

    #success-header{
      position: static;
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      padding-top: 40%;
      text-align: center;
    }
  }

@media (min-width: 1024px){
  .success-modal{
    width: 100%;
    height: 100%;
    background-color: #151515;
  }
  .button-wrapper{
    margin-top: 44px;
    width: 100%;
}
  .success-modal .subTitle{
    height: auto;
    margin: 16px 0px 0px 0px
  }
  .success-modal-inner{
    position: static;
    width: 720px;
  }
  .title{
    width: 442px;
    height: 140px;
    left: 64px;
    top: 240px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 70px;
    color: #F6F6F6;
}
  .success-modal-contents {
    background-color: transparent;
    position: static;
    width: 80%;
    height: 100%;
  }
  .displayImage{
    background-size: contain;
    max-height: 600px;
  }
  .header-wrapper {
    margin-top: 150px;
    width: 40%;
    margin-left: 48px;
  }

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .success-modal p,
  .success-modal span{
    color: ${colors.secondaryText};
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin: 0px;
  }

  .terms-and-button-wrapper {
    position: static;
    padding-top: unset;
    left: 0;
    right: 0;
    margin-left: -3px;
    margin-right: auto;
    top: unset;
    bottom: 5%;
    width: 300px;
  }

  #success-terms {
    text-align: left;
  }

  #success-header{
    position: static;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    padding-top: 40%;
    text-align: center;
  }
}

@media (min-width: 2000px){
  .success-modal{
    width: 100%;
    height: 100%;
    background-color: #151515;
  }

  .success-modal-contents {
    position: relative;
    top: 0;
    margin: auto;
  }

  .header-wrapper {
    width: 700px;
    margin: auto;
  }

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .success-modal p,
  .success-modal span{
    color: ${colors.secondaryText};
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    text-align: center;
  }

  .terms-and-button-wrapper {
     width: 30vw !important;
    margin: auto;
    margin-left: 0px;
  }

  #success-header{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 124px;
    line-height: 132px;
    padding-top: 5vh;

    text-align: center;
  }
  .success-modal :global(.success-secondary){
    height: 72px;
    font-size: 32px;
    border-radius: 8px;
    color: white;
    font-weight: 400;
    padding-bottom: 35px;
    border: none;
    width: 100%;
    line-height: 40px;
  }
  .success-modal :global(.success-dismiss){
    height: 72px;
    font-size: 32px;
    border-radius: 8px;
    color: white;
    font-weight: 400;
    padding-bottom: 35px;
    border: none;
    width: 100%;
    line-height: 40px;
  }
}

@media (min-width: 3000px){
  #success-header{
    font-size: 194px;
    line-height: 204px;
  }

  .terms-and-button-wrapper {
    width: 30vw;
    margin: auto;
  }

  .success-modal :global(.success-dismiss){
    height: 100px;
    font-size: 52px;
    border-radius: 8px;
    color: white;
    font-weight: 400;
    padding-bottom: 35px;
  }
}
`
