import moment from 'moment'
import { STD_DATE_FORMAT } from '../../constants/player-plus' // eslint-disable-line import/no-cycle
import PlayerApi from './playerApi'

const playerApi = PlayerApi()

const numbersToWords = {
  0: 'zero',
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
  11: 'eleven',
  12: 'twelve',
  13: 'thirteen',
  14: 'fourteen',
  15: 'fifteen',
  16: 'sixteen',
  17: 'seventeen',
  18: 'eighteen',
  19: 'nineteen',
  20: 'twenty',
  30: 'thirty',
  40: 'forty',
  50: 'fifty',
  60: 'sixty',
  70: 'seventy',
  80: 'eighty',
  90: 'ninety',
}

export function getCurrentUrl() {
  return process.browser && window.location.href
}

export function getCurrentPath() {
  const path = window.location.pathname + window.location.search
  return process.browser && path
}

export function getCurrentDomain(domain = window.location.hostname) {
  const parts = domain.split('.').reverse()
  return `${parts[1]}.${parts[0]}`
}

export function getPlayerUrl(path = '') {
  const currentDomain = getCurrentDomain()
  const env = getEnvironment(currentDomain)

  // Use stvqa.tv for both development and staging environments
  const baseDomain = env === 'production' ? 'stv.tv' : 'stvqa.tv'
  return `https://player.${baseDomain}${path}`
}

export function getEnvironment(domain) {
  switch (domain) {
    case 'stv.tv':
      return 'production'
    case 'stvqa.tv':
      return 'staging'
    default:
      return 'development'
  }
}

export function getDomainWithPort() {
  return getEnvironment(getCurrentDomain()) === 'production'
    ? `https://data.api.stv.tv`
    : `https://data.api.stvqa.tv`
}

export function isSecureProtocol() {
  return process.browser && window.location.protocol === 'https:'
}

function regexEscape(str) {
  return str.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export function parameterize(string, seperator = '-') {
  const escapedSeperator = regexEscape(String(seperator))
  return string
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9\-_]/g, seperator)
    .replace(new RegExp(`${escapedSeperator}{2,}`, 'g'), seperator)
    .replace(new RegExp(`^${escapedSeperator}|${escapedSeperator}$`, 'g'), '')
}

export function userHasAddress(address) {
  try {
    if (!address.formatted) {
      return false
    }
    if (address.formatted.postal_code) {
      return true
    }
    const parsedAddress = JSON.parse(address.formatted)
    const parsedPostalCode = parsedAddress.postal_code
    if (!parsedAddress || !parsedPostalCode) {
      return false
    }
    return parsedPostalCode !== 'N A'
  } catch (e) {
    return false
  }
}

export function getTrialExpiryDate() {
  const trialExpiry = moment().add(7, 'days').format(STD_DATE_FORMAT)
  return trialExpiry
}

export function getFirstNonZeroPaymentDate(hasTrial, promoDurationMonths, promoPrice) {
  let paymentDate = moment()

  if (hasTrial) {
    paymentDate = paymentDate.add(7, 'days')
  }

  paymentDate = promoPrice > 0 ? paymentDate : paymentDate.add(promoDurationMonths, 'months')

  return paymentDate.format(STD_DATE_FORMAT)
}

export function getFirstFullPaymentDate(hasTrial, promoDurationMonths) {
  let paymentDate = moment()

  if (hasTrial) {
    paymentDate = paymentDate.add(7, 'days')
  }

  if (promoDurationMonths) {
    paymentDate = paymentDate.add(promoDurationMonths, 'months')
  }

  return paymentDate.format(STD_DATE_FORMAT)
}

export function convertNumberToWords(number) {
  if (number in numbersToWords) return numbersToWords[number]

  let words = ''

  if (number >= 100) {
    words += `${convertNumberToWords(Math.floor(number / 100))} hundred`
    // eslint-disable-next-line no-param-reassign
    number %= 100
  }

  if (number > 0) {
    if (words !== '') words += ' and '

    if (number < 20) words += numbersToWords[number]
    else {
      words += numbersToWords[Math.floor(number / 10) * 10]

      if (number % 10 > 0) {
        words += `-${numbersToWords[number % 10]}`
      }
    }
  }
  return words
}

export function refreshCognitoUser(loginService) {
  const userObject = loginService.getCognitoUserObject()
  return loginService.getUserSession(userObject)
    .then(session => (
      loginService.refreshUserSession({
        user: userObject,
        session,
      })
    )).then(() => (loginService.getUser()))
}

export function generateChannelString(length) {
  const possible = 'bcdfghjklmnpqrstvwxyz0123456789'
  return Array.from({ length }, () => possible.charAt(Math.floor(Math.random() * possible.length)))
    .join('')
}

export async function getPlayerSettingStatus(setting) {
  try {
    return playerApi({
      path: `/settings/${setting}`,
    }).then(response => response)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.Error('Could not get player setting', e)
    return { success: false }
  }
}
