import React from 'react'
import PropTypes from 'prop-types'
import { getPlayerUrl } from '../../modules/utils'
import Button from '../Button'
import styles from './styles'

const copy = {
  free: 'You can access STV Player, completely free and catch up on your favourite shows with ads.',
  plus: 'You can now start enjoying your new benefits such as 1000s of hours of ad-free shows.',
  premier_sports: 'You can now start enjoying your new benefits including live sports and 1000s of hours of ad-free shows.',
}


const SuccessModal = ({
  subscriptionType,
}) => {
  const noun = subscriptionType === 'free' ? subscriptionType : 'subscriber'
  return (
    <div className="success-modal">
      <div className="success-modal-inner">
        <div className={`success-copy-container ${subscriptionType}`}>
          <div className={`logo ${subscriptionType}`} />
          <div className={`success-copy ${subscriptionType}`}>
            <h1 className="success-headline">
              You are now a <span className={`highlight ${subscriptionType}`}>{noun}</span> {subscriptionType === 'free' ? 'user' : ''}
            </h1>
            <p className="success-subtitle">{copy[subscriptionType]}</p>
            <div className="success-button-container">
              <Button
                className="success-dismiss-ps"
                variant="primary"
                href={getPlayerUrl()}
              >
                Let's go
              </Button>
            </div>
          </div>
        </div>
        <div className={`success-image ${subscriptionType}`} />
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}

export default SuccessModal

SuccessModal.propTypes = {
  subscriptionType: PropTypes.string,
}

SuccessModal.defaultProps = {
  subscriptionType: 'free',
}
