import React, { lazy, Suspense } from 'react'
import LoadableSpinner from '../../components/LoadableSpinner'

const AsyncArticleWrapper = lazy(() => import('./Article'))

const AsyncArticle = () => (
  <Suspense fallback={<LoadableSpinner centered />}>
    <AsyncArticleWrapper />
  </Suspense>
)

export default AsyncArticle
