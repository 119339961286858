/* eslint-disable import/prefer-default-export */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import {
  closeButton,
  modalBody,
  modalButtons,
  modalHeader,
  modalLayout,
} from './styles'

export const CloseButton = forwardRef(({ onClick }, ref) => (
  <button
    type="button"
    className="modal-close"
    ref={ref}
    onClick={onClick}
  >
    Close
    <style jsx>{closeButton}</style>
  </button>
))

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export const ModalHeader = forwardRef(({ text, id }, ref) => (
  <h3 id="id" className="modal-header" ref={ref}>
    { text }
    <style jsx>{modalHeader}</style>
  </h3>
))

ModalHeader.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export const ModalBody = ({ children }) => (
  <div className="modal-body">
    { children }
    <style jsx>{modalBody}</style>
  </div>
)

ModalBody.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export const ModalButton = ({
  onClick, variant, text, isWaiting,
}) => (
  <Button
    className="modal-button"
    variant={variant}
    onClick={onClick}
    loading={isWaiting}
  >
    <span className="button-text" aria-live="polite">
      { text }
    </span>
  </Button>
)

ModalButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  variant: PropTypes.PropTypes.oneOf(['secondary', 'primary', 'warning']).isRequired,
  isWaiting: PropTypes.bool,
}

ModalButton.defaultProps = {
  onClick: () => {},
  isWaiting: false,
}


export const ModalButtons = ({ children }) => (
  <div className="modal-buttons">
    { children }
    <style jsx>{modalButtons}</style>
  </div>
)

ModalButtons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export const ModalLayout = ({ children, className }) => (
  <div className={className}>
    { children }
    <style jsx>{modalLayout}</style>
  </div>
)

ModalLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  className: PropTypes.string, // eslint-disable-line
}

ModalLayout.defaultProps = {
  className: '',
}
