import LoginService from '@stvgroup/login-service'
import { getCurrentDomain, getEnvironment, isSecureProtocol } from './modules/utils'

// Add TP domain detection
const isTpDomain = hostname => /^\d+\.my\.stvqa\.tv$/.test(hostname)
const getLoginDomain = () => {
  const { hostname } = window.location
  // If it's a TP domain, use its full domain for cookies
  if (isTpDomain(hostname)) {
    return hostname
  }
  // Otherwise use the normal domain logic
  return getCurrentDomain()
}

const loginService = LoginService({
  currentDomain: getLoginDomain(),
  environment: getEnvironment(getCurrentDomain()),
  isSecureProtocol: isSecureProtocol(),
})


export default loginService
