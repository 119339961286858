import React, {
  createContext, useContext, useEffect, useMemo,
} from 'react'
import PropTypes from 'prop-types'
import FOOTNOTES from '../../constants/footnotes'

const FootnotesContext = createContext({
  registerFootnote: () => {},
  unregisterFootnote: () => {},
  activeFootnotes: [],
})

export const FootnotesProvider = ({ children }) => {
  const [activeFootnotes, setActiveFootnotes] = React.useState([])

  const registerFootnote = React.useCallback((key) => {
    setActiveFootnotes(prev => [...new Set([...prev, key])])
  }, [])

  const unregisterFootnote = React.useCallback((key) => {
    setActiveFootnotes(prev => prev.filter(k => k !== key))
  }, [])

  const contextValue = useMemo(() => ({
    registerFootnote,
    unregisterFootnote,
    activeFootnotes,
  }), [registerFootnote, unregisterFootnote, activeFootnotes])

  return (
    <FootnotesContext.Provider value={contextValue}>
      {children}
    </FootnotesContext.Provider>
  )
}

FootnotesProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const WithFootnote = ({ footnoteKey, children }) => {
  const { registerFootnote, unregisterFootnote, activeFootnotes } = useContext(FootnotesContext)


  useEffect(() => {
    registerFootnote(footnoteKey)
    return () => unregisterFootnote(footnoteKey)
  }, [footnoteKey, registerFootnote, unregisterFootnote])

  const sortedFootnotes = [...activeFootnotes].sort((a, b) => FOOTNOTES[a].localeCompare(FOOTNOTES[b])
  )
  const footnoteIndex = sortedFootnotes.indexOf(footnoteKey)
  const asterisks = '*'.repeat(footnoteIndex + 1)
  return (
    <span className="relative">
      {children}
      {asterisks}
    </span>
  )
}

WithFootnote.propTypes = {
  footnoteKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export const FootnotesSection = () => {
  const { activeFootnotes } = useContext(FootnotesContext)
  const sortedFootnotes = [...activeFootnotes].sort((a, b) => FOOTNOTES[a].localeCompare(FOOTNOTES[b])
  )
  return (
    <>
      <div className="footnotes">
        {sortedFootnotes.map((key, index) => (
          <span key={key} className="footnote-item">
            {'*'.repeat(index + 1)}
            {FOOTNOTES[key]}
            {' '}
          </span>
        ))}
      </div>
      <style jsx>
        {`
          .footnote-item {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #C4C4C4;
          }
        `}
      </style>
    </>
  )
}
