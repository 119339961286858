/* eslint-disable no-console */
import getApiUrl from '../getApiUrl'

export default () => ({
  path = '',
  method = 'GET',
  body = null,
}) => {
  const headers = {}
  const environment = getEnv()
  const url = buildRequestUrl({
    environment,
    path,
  })
  const request = () => {
    logRequest({ url, environment })

    return fetchWithTimeout({
      headers,
      url,
      method,
      body,
    })
  }
  return request()
}

const getEnv = () => (window.location.hostname.endsWith('stv.tv')
  ? 'production'
  : 'staging'
)

const logRequest = ({ url, environment }) => {
  if (environment === 'development' || environment === 'staging') {
    console.log(`--- Request made to: ${url} | ${new Date()}`)
  }
}

export const setTpNumber = (number) => {
  const environment = getEnv()
  if (environment === 'production') {
    console.error('TP number can only be set in development or staging environments')
    return
  }

  if (number === null || number === undefined) {
    localStorage.removeItem('tpNumber')
    console.log('TP number removed')
    return
  }

  if (!number || typeof number !== 'string') {
    console.error('TP number must be a non-empty string')
    return
  }

  localStorage.setItem('tpNumber', number)
  console.log(`TP number set to: ${number}`)
  console.log('Current API URL:', buildRequestUrl({
    environment,
    path: '',
  }))
}

export function buildRequestUrl({ environment, path }) {
  const tp = localStorage.getItem('tpNumber')
  const prefix = (environment !== 'production' && tp) ? `${tp}.` : ''
  const base = [
    `https://${prefix}data.api.`,
    getApiUrl(environment),
    '/v2',
    path,
  ].join('')
  return base
}

export function fetchWithTimeout({
  url = '',
  time = 10000,
  headers,
  method,
  body,
}) {
  const timeout = new Promise(resolve => setTimeout(() => {
    const error = {
      success: false,
      reason: `Timed out after ${time}ms: ${url}`,
    }
    resolve(error)
  }, time))
  return Promise.race([
    fetch(url, {
      headers,
      method,
      body,
    })
      .then(r => r.json()),
    timeout,
  ])
}
