import {
  SET_USER,
  CLEAR_USER,
  UPDATE_USER_SUBSCRIPTION,
  SET_STRIPE_DETAILS,
  SET_SUBSCRIPTION_ERROR,
} from '../constants/action_types'

export function setUser(user) {
  return {
    type: SET_USER,
    payload: user,
  }
}

export function clearUser() {
  return {
    type: CLEAR_USER,
  }
}

export function updateUserSubscription(subscriptionInfo) {
  return {
    type: UPDATE_USER_SUBSCRIPTION,
    payload: subscriptionInfo,
  }
}

export function setStripeDetails(stripeDetails) {
  return {
    type: SET_STRIPE_DETAILS,
    payload: stripeDetails,
  }
}

export function setSubscriptionError(error) {
  return {
    type: SET_SUBSCRIPTION_ERROR,
    payload: error,
  }
}
