import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
.button {
  display: block;
  border-radius: 8px;
  text-align: center;
  white-space: normal;
  padding: 12px;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  width: 100%;
  transition: background 0.2s;
  margin: 8px auto;
}

.button:disabled {
  cursor: not-allowed;
  background: #232323;
  color: #6d6d6d;
}

.small {
  font-size: 12px;
  line-height: 1.25;
  display: block;
  border-radius: 5px;
  padding: 10px;
  transition: 0.2s;
}

.primary {
  border: none;
  background: ${colors.primaryText};
  color: #212121;
}

.blank {
  border: none;
  background: ${colors.textInput};
  width: 100%;
  border-radius: 8px;
}

.secondary {
  background-color: transparent;
  color: ${colors.primaryText};
  border: 1px solid ${colors.primaryText};
}

.primary:focus,
.secondary:focus {
  box-shadow:  0 0 2px 2px #DD96FF;
}

.close{
  width: 38px;
  height: 38px;
  line-height: 40px;
  position: absolute;
  top:13px;
  right: 14px;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  border-radius: 50%;
  background: rgba(0,0,0,.4);
  font-weight: 700;
  outline: 2px;
  cursor: pointer;
  padding: 0;
  border: 0;
}

.close:focus{
  box-shadow: 0 0 5px 2px ${colors.primaryText}
}

.close:after {
  font-size: 32px;
  content: '\00d7';
  color: ${colors.primaryText};
  text-indent: 0;
  position: absolute;
  top: -2px;
  display: block;
  width: 100%;
  font-weight: 300;
}

.warning {
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: ${colors.red};
  color:${colors.primaryText};
  text-align: center;
  font-size: 18px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

button.primary-purple.payment-button {
  border: none;
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
}

:global(.loading-dots) {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: loading-dots 1s infinite linear alternate;
  animation-delay: 0.5s;
  line-height: 20px;
  display: inline-block;
}
:global(.loading-dots::before), :global(.loading-dots::after) {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
:global(.loading-dots::before) {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: loading-dots 1s infinite alternate;
  animation-delay: 0s;
}
:global(.loading-dots::after) {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: loading-dots 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes loading-dots {
  0% {
    background-color: #9880ff;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

@media (min-width: 767px){
  .close {
    width: 44px;
    height: 44px;
    line-height: 48px;
    border-radius: 50%;
  }

  .close:after {
    font-size: 36px;
    color: ${colors.primaryText};
  }
}
`
